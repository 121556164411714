import '../cruces/RV.css'

import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/es'
import axios from 'axios'
import ItinerarioContext from '../../context/ItinerarioContext';
import useInterval from '../../Hooks'
import Estado from '../cruces/Estado2'
import a from '../../assets/images/rv.png';


const Rv = (props) => {
    
    const [rv, setRv] = useState(0)
    const itinerarioCtx = useContext(ItinerarioContext)
    const hoy = moment()
    const fecha = moment(props.fecha, 'DD-MM-Y')
    var anterior = ''
    moment.locale('es')
    
    const estados = {
        8: { es: 'Normal', en: 'Normal', class: 'normal'},
        4: { es: 'Suspendido', en: 'Suspended', class: 'suspendido'},
    }

	useEffect( () => {
        if ( props.hoy === true) {
            if (moment(props.fecha, 'DD-MM-Y').format('DD') < hoy.format('DD')) {
                window.location.reload()
            } 
        }
		const fetchData = async () => {
            fetchTimeout()
            const apiUrl = `https://api-cruce.tabsa.cl/api/cruce/rio-verde`
            console.log(apiUrl)
            const result = await axios(apiUrl)
            const data = await result.data
            setRv(data)
        }
		fetchData()
    }, [itinerarioCtx.cargando])
    
    const fetchTimeout = () => {
        console.log('start timetout!')
        setTimeout( function() {
            itinerarioCtx.updateCargando(false)
        }, 15000)
    }

    useInterval( () => {
        
        itinerarioCtx.updateCargando(true)

    }, 60000)

    return (
        <div className="crucesWrapper">
        { ! rv 
            ? ""
            :   (
                        <>
                        <table className={`table `}>
                            <thead>
                                <tr className="cabecera">
                                    {/* <th className="lugar">Lugar <span className="cabeceraIngles">Place</span></th>
                                    <th className="cabeceraEstado">Estado <span className="cabeceraIngles">Status</span></th> */}
                                </tr>
                            </thead>
                            <tbody><tr></tr>
                                { 
                                    rv.data.map(({ id_lugar, lugar, estado, }) => {
                                        return (
                                            <tr key={`cruce-${id_lugar}`}>
                                                <td className="t">{lugar}</td>
                                                <td className="t4">08:10 - 19:00</td>
                                                <td className="t2"><Estado es={estados[estado].es} en={estados[estado].en} estilo={estados[estado].class} /></td>
                                                <td><img className="rv" src={a}/></td>
                                            </tr>
                                        )
                                    }
                                    
                                    
                                    )
                                }
                            </tbody>
                        </table>
                        </>
            )
        } 
        </div>
    )
}


export default Rv