import React, { useState, useEffect } from 'react'
import axios from 'axios'
import useInterval from '../../Hooks'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'
import './Cupo.css'

const Cupo = (props) => {
     
     const [idCruceMasCercano, setIdCruceMasCercano] = useState(0)
     const [cupoTotal, setCupoTotal] = useState(0)
     const [cupoActual, setCupoActual] = useState(0)
     const [porcentaje, setPorcentaje] = useState(0)
     const [reload, setReload] = useState(true)
     const [reloadCupos, setReloadCupos] = useState(false)
     const [updatePercent, setUpdatePercent] = useState(false)
     var mensajeEs = '¡Últimos cupos disponibles!'
     var mensajeEn = 'Last seats avalaible!'
     const consultarEn = 'Check for avalaibility with our executives'
     const consultarEs = 'Consulte disponibilidad con nuestros ejecutivos'
     const [mensajeFinal, setMensajeFinal] = useState( mensajeEs )

     useEffect( () => {
             const apiUrl = `https://api-cruce.tabsa.cl/api/cruce/origen/1/cercanos/3`
             const fetchData = async() => {
                 const result = await axios(apiUrl)
                 const data = await result.data
                 if(data.data[0]){
                     setIdCruceMasCercano(data.data[0].id)
                 }
                 setReloadCupos(!reloadCupos)
             }
             fetchData()
     }, [reload])

     useEffect( () => {
         if (idCruceMasCercano) {
             const apiUrl = `https://api-cruce.tabsa.cl/api/cruce/${idCruceMasCercano}/cupos`
            // const apiUrl = `https:api-cruce.tabsa.cl/api/tramo/${localStorage.getItem('id_embarcadero')}/cupos`
             const fetchData = async() => {
                 const result = await axios(apiUrl)
                 const data = await result.data
                //  setCupoActual(data.data[0].actual)
                //  setCupoTotal(data.data[0].maximo)
                //  setUpdatePercent(!updatePercent)
             }
             fetchData()
         }

     }, [reloadCupos])

     useEffect( () => {
         if (cupoActual !== 0 && cupoTotal !== 0) {    
             setPorcentaje( 100 - Math.round( (cupoActual * 100) / cupoTotal ) )
         } 
     }, [updatePercent])

     useInterval( () => {
         setReload(!reload)
     }, 60000)

     useInterval( () => {
         if ( porcentaje < 10 ) {

             if ( porcentaje < 2 ) {
                 mensajeEs = consultarEs
                 mensajeEn = consultarEn
             }

             setMensajeFinal( mensajeFinal === mensajeEs ? mensajeEn:mensajeEs )

         }
     }, 5000)

     const estilosProgress = {
             trail: {
                 stroke: 'white'
             }, 
             path: { 
                 stroke: 'orange'
             },
             text: {
                 fill: 'orange',
                 fontSize: '30px'
             } 
         }
      
     return (
         porcentaje 

         ?   (
             porcentaje > 10 
             ?   (
                     <div className="infoCupos">
                         <div className="contadorCupos"> 
                             <CircularProgressbar value={porcentaje} text={`${porcentaje}%`} styles={estilosProgress} /> 
                         </div> 
    
                         <div className="detalleCupos"> 
                             <h1> 
                                 <small>Asientos disponibles para</small> 
                                 Próximo Cruce     
                             </h1> 
                         </div> 
                     </div> 
                 )
             :   (
                     <div className="infoCupos rojo" >
                         <div className="ultimosCupos"> 
                             <h1>{mensajeFinal}</h1> 
                         </div> 
                     </div> 
                )
            )
        
        :   ""
        
    )
}

export default Cupo